import { useEffect, useState } from "react";
import { AppContext } from "./context/AppContexto";
import { AppRouter } from "./routers/AppRouter";
import axios from "axios";
import { Config } from "./config/Config";
import { RequestUrls } from "./api/API";

export const AppComponent = () => {
  const [listItems, setListItems] = useState([]);
  const [dates, setdates] = useState({});
  const [showSearch, setshowSearch] = useState(true);
  const [footer, setfooter] = useState([]);
  const [sistemasProduct, setSistemasProduct] = useState([]);
  const [urls, seturls] = useState([]);
  const [backgrounds, setbackgrounds] = useState([]);
  const [icons, seticons] = useState([]);
  const [zonasAptas, setZonasAptas] = useState([]);
  const [zonasAptasMunicipios, setZonasAptasMunicipios] = useState([]);
  const [contextos, setContextos] = useState([]);
  const [itemsInteres, setItemsInteres] = useState([]);
  const [infoTobBar, setInfoTobBar] = useState({});
  const [termn, settermn] = useState("");

  const [postData, setPostData] = useState({
    id_cultivo: "",
    id_riesgo: 1,
    id_modulo: 1,
    id_contexto: 3,
    id_amenaza: "",
  });

  const [infoWidgetsMaps, setInfoWidgetsMaps] = useState({});


  useEffect(() => {
    const headers = {
      'Content-Encoding': 'gzip, deflate, br'
    }

    let texts = Config.baseUrlJsonMaster;
    axios
      .get(texts, headers)
      .then((response) => {
        setSistemasProduct(response.data.sistemas_productivos);
        setfooter(response.data.footer);
        setListItems(response.data.navigate);
        seturls(response.data.Url_services[3].exposicion);
        setbackgrounds(response.data.backgrounds[0]);
        seticons(response.data.icons[0]);
        setZonasAptas(response.data.zonas_aptitud);
        setZonasAptasMunicipios(response.data.zonas_aptitud_mun);
        setContextos(response.data.contextos);
        setInfoTobBar(response.data.top_section);
        console.log("Bandera",response.data.top_section)
      })
      .catch((err) => err);

    axios.get(`${RequestUrls._emailIntereses}`)
      .then(({ data }) => {
        const intereses = [];
        data.data.map((interes) => {
          intereses.push({
            label: interes.interes,
            value: interes.id,
          });
        });
        setItemsInteres(intereses);
      })
      .catch((err) => err);
  }, []);

  const [info, setinfo] = useState({}); // Cambiar banner
  const [share, setShare] = useState(window.localStorage.getItem("sistema"));

  const [darkTheme, setDarkTheme] = useState(
    JSON.parse(localStorage.getItem("darkTheme")) ?? false
  );
  const [size, setSize] = useState(0);
  const _sizes = ["", "sm", "xl"];

  if (_sizes.length - 1 < size || size < 0) {
    setSize(0);
  }

  useEffect(() => {
    localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
  }, [darkTheme]);

  const [metricas, setMetricas] = useState({
    Service_Name: '',
    Download: false,
    Year: '',
    Month: '',
    Period: '',
    Depto: '',
    Mipio: '',
    LabelDepto: '',
    LabelMipio: '',
    Version: '',
  })

  return (
    <AppContext.Provider
      value={{
        termn,
        settermn,
        setInfoWidgetsMaps,
        infoWidgetsMaps,
        infoTobBar,
        postData,
        setPostData,
        listItems,
        setshowSearch,
        showSearch,
        urls,
        info,
        setinfo,
        share,
        setShare,
        setDarkTheme,
        darkTheme,
        setSize,
        size,
        _sizes,
        dates,
        setdates,
        footer,
        sistemasProduct,
        backgrounds,
        icons,
        zonasAptas,
        contextos,
        itemsInteres,
        setMetricas,
        metricas,
        zonasAptasMunicipios,
        setZonasAptasMunicipios
      }}
    >
      <AppRouter />
    </AppContext.Provider>
  );
};
